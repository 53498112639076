import type { NetworkPayload } from "../../../composites";

const API = {
  fetchSensitiveCardInfo: (cardId: string): NetworkPayload => {
    return {
      url: `cards/${cardId}/sensitive`,
      options: {
        method: "GET",
      },
    };
  },
};

export default API;
