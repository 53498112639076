import { modules } from "byzantine";
import App from "byzantine/src/App";
import { useLocalization } from "@fluent/react";
import { Button } from "@narmi/design_system";
import { InstitutionFeatures } from "byzantine/src/types";
import { useUserFeatures } from "../contexts/UserFeaturesContext";
import SectionCard from "../SectionCard";
import AppAuthorize from "../AppAuthorize";

const AoiobItem = () => {
  const { l10n } = useLocalization();
  const { fetchAoiobLink } = modules.aoiob.useFetchAoiobLink();

  const handleClick = () => {
    fetchAoiobLink({
      onSuccess: ({ link }: { link: string }) => {
        window.open(link, "_blank");
      },
    });
  };

  return (
    <div className="dashboard-item-border-box">
      <div className="brand-hover">
        <Button
          as="a"
          kind="menu"
          data-classname="AppAuthorize services-button"
          tabIndex="0"
          onKeyUp={({ key }: { key: string }) => {
            if (key === "Enter") {
              handleClick();
            }
          }}
          onClick={handleClick}
        >
          <div className="dashboard-item">
            <div className="dashboard-item-subsection dashboard-item-icon-section">
              <span className="narmi-icon-plus-square"></span>
            </div>
            <div className="dashboard-item-subsection dashboard-item-text-section">
              <div className="dashboard-item-name">
                {l10n.getString(
                  "services-item-open-an-account",
                  {},
                  "Open an account",
                )}
              </div>
              <div className="dashboard-item-description">
                {l10n.getString(
                  "services-item-open-an-account-description",
                  {},
                  "Open an account with us!",
                )}
              </div>
            </div>
          </div>
        </Button>
      </div>
    </div>
  );
};

interface ServiceItemProps {
  service: App;
}
const ServiceItem = ({ service }: ServiceItemProps) => (
  <div className="dashboard-item-border-box">
    <div className="brand-hover">
      <AppAuthorize app={service} dataClassnames="services-button">
        <div className="dashboard-item">
          <div className="dashboard-item-subsection dashboard-item-icon-section">
            <span className={service.getNdsIconClassname()}></span>
          </div>
          <div className="dashboard-item-subsection dashboard-item-text-section">
            <div className="dashboard-item-name">{service.name}</div>
            <div className="dashboard-item-description">
              {service.description}
            </div>
          </div>
        </div>
      </AppAuthorize>
    </div>
  </div>
);

interface ServicesCardProps {
  services: App[];
}

const ServicesCard = ({ services }: ServicesCardProps) => {
  const { aoiob } = useUserFeatures() as InstitutionFeatures;
  if (services.length < 1 && !aoiob) return null;
  return (
    <SectionCard isLoading={false} classNames="dashboard-section-card">
      <SectionCard.Title text="Services" className="services-title" />
      <div className="dashboard-section-container">
        {services.map((service) => (
          <ServiceItem key={service.id} service={service} />
        ))}
        {aoiob && <AoiobItem />}
      </div>
    </SectionCard>
  );
};

export default ServicesCard;
