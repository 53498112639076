import User from "./User";
import Filters from "./filters";
import ApiHttp from "./ApiHttp";

export type DualApprovalRequestProps = {
  uuid: string;
  created_at: Date;
  requester: User;
  responder: User | null;
  action: DualApprovalRequestActionProps;
};

export type DualApprovalRequestActionProps =
  | (API.Wire & {
      type: "Wire";
      recipientName: API.Recipient["name"];
      recipientFormattedAddress: string;
      formattedMemo: string;
      dollarAmount: Dollars;
    })
  | (API.ACHPayment & {
      type: "ACH";
      dollarAmount: Dollars;
      recipientName: API.Recipient["name"];
      recipientAccountNumber: NonNullable<
        API.Recipient["ach_destination"]
      >["account_number"];
      recipientRoutingNumber: NonNullable<
        API.Recipient["ach_destination"]
      >["routing_number"];
      recipientAccountInstitution: NonNullable<
        API.Recipient["ach_destination"]
      >["institution_name"];
      recipientLastUpdated: NonNullable<API.Recipient["updated_at"]>;
    });
class DualApprovalRequestAction {
  constructor(props: API.AnyDualApproval, recipients?: API.Recipient[]) {
    const wireProps = props as API.WireDualApproval;
    const achProps = props as API.ACHDualApproval;

    if (wireProps.wire) {
      const { wire } = wireProps;
      const addressParts = [
        wire.beneficiary_address_1,
        wire.beneficiary_address_2,
        wire.beneficiary_address_3,
      ];
      const memoParts = [wire.memo_1, wire.memo_2, wire.memo_3, wire.memo_4];
      const formattedAddress = addressParts.filter((line) => line).join(`\n`);
      const formattedMemo = memoParts.filter((line) => line).join(`\n`);

      Object.assign(this, {
        ...wire,
        type: "Wire",
        dollarAmount: Filters.penniesToDollars(wire.amount),
        recipientName: wire.beneficiary_name,
        recipientFormattedAddress: formattedAddress,
        formattedMemo,
      } as DualApprovalRequestActionProps);
    } else if (achProps.ach_payment) {
      const { ach_payment: achPayment } = achProps;
      const matchedRecipient = recipients?.find(
        (recipient) => recipient.id === achPayment.recipient,
      );

      Object.assign(this, {
        ...achPayment,
        type: "ACH",
        dollarAmount: Filters.penniesToDollars(achPayment.amount),
        recipientName: matchedRecipient?.name,
        recipientAccountNumber:
          matchedRecipient?.ach_destination?.account_number,
        recipientRoutingNumber:
          matchedRecipient?.ach_destination?.routing_number,
        recipientAccountInstitution:
          matchedRecipient?.ach_destination?.institution_name,
        recipientLastUpdated: matchedRecipient?.updated_at,
      } as DualApprovalRequestActionProps);
    }
  }
}

export default class DualApprovalRequest {
  public uuid: DualApprovalRequestProps["uuid"];

  public created_at: DualApprovalRequestProps["created_at"];

  public requester: DualApprovalRequestProps["requester"];

  public responder: DualApprovalRequestProps["responder"];

  public action: DualApprovalRequestProps["action"];

  constructor(props: DualApprovalRequestProps) {
    this.uuid = props.uuid;
    this.created_at = props.created_at;
    this.requester = props.requester;
    this.responder = props.responder;
    this.action = props.action;
  }

  approve() {
    const payload = { action: "approve" };
    return ApiHttp.fetch(
      `approval_requests/${this.uuid}`,
      { method: "POST" },
      payload,
    ).then((response) => {
      const approvalRequest =
        response.approval_request.ach_payment || response.approval_request.wire;
      this.action.state = approvalRequest.state;
      return response;
    });
  }

  reject() {
    const payload = { action: "reject" };
    return ApiHttp.fetch(
      `approval_requests/${this.uuid}`,
      { method: "POST" },
      payload,
    ).then((response) => {
      const approvalRequest =
        response.approval_request.ach_payment || response.approval_request.wire;
      this.action.state = approvalRequest.state;
      return response;
    });
  }

  static deserialize(
    payload: API.AnyDualApproval,
    recipients?: API.Recipient[],
  ) {
    return new DualApprovalRequest({
      uuid: payload.uuid,
      created_at: new Date(payload.created_at),
      requester: User.deserialize(payload.requester),
      responder: payload.responder ? User.deserialize(payload.responder) : null,
      action: new DualApprovalRequestAction(
        payload,
        recipients,
      ) as DualApprovalRequestActionProps,
    });
  }
}
