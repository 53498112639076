import { useEffect, useState } from "react";
import { useNotificationContext, LoadingSkeleton } from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";
import { useNavigate, useParams } from "react-router-dom";

import { useLocalization } from "@fluent/react";

import BankCard from "../BankCard";
import CardManagerDrawer from "../CardManagerDrawer";
import useIsCardManagerDrawerOpen from "./useIsCardManagerDrawerOpen";
import styles from "./BankCardList.module.scss";

export interface CardListResponse {
  results: API.Card[];
}

const BankCardList = () => {
  const { sendNotification } = useNotificationContext();
  const isCardManagerOpen = useIsCardManagerDrawerOpen();
  const { l10n } = useLocalization();
  const navigate = useNavigate();
  const [cards, setCards] = useState<API.Card[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { id: selectedCardId } = useParams();

  useEffect(() => {
    const requestCards = async () => {
      try {
        const response = await ApiHttp.fetch<CardListResponse>(
          "cards",
          { method: "GET", headers: { "API-Version": "0017" } },
          {},
        );
        if (!response?.results) {
          return;
        }
        const cardsResponse = response?.results.map((card) => {
          const cardSvg = window.btoa(card.image);
          return {
            ...card,
            image: `data:image/svg+xml;base64,${cardSvg}`,
          };
        });
        setCards(cardsResponse);
      } catch (error) {
        sendNotification({
          type: "negative",
          text: l10n.getString("error-unknown", null, "Something went wrong"),
        });
        setCards([]);
      }
      setIsLoading(false);
    };

    requestCards();
  }, []);

  const updateCardStateById = (
    cardId: string,
    cardState: API.Card["state"],
  ) => {
    const cardToUpdateIndex = cards.findIndex((card) => card.id === cardId);
    cards[cardToUpdateIndex] = {
      ...cards[cardToUpdateIndex],
      state: cardState,
    };
    setCards([...cards]);
  };

  return (
    <LoadingSkeleton isLoading={isLoading}>
      <div className={styles.cardList}>
        {cards.length === 0 && <div>{l10n.getString("message-no-cards")}</div>}

        {cards.map((card) => (
          <div key={card.id} className={styles.cardContainer}>
            <BankCard
              textColor={card.text_color}
              isLocked={card.state === "locked"}
              image={card.image}
              cardId={card.id}
              name={card.name}
              lastFourDigits={card.last_four_digits}
              network={card.network}
              state={card.state}
              onManageClick={() => {
                navigate(`/cards/${card.id}`);
              }}
            />
          </div>
        ))}
      </div>
      <CardManagerDrawer
        isOpen={isCardManagerOpen}
        onUserDismiss={() => {
          navigate("/cards");
        }}
        card={cards.find((card) => card.id === selectedCardId) || null}
        updateCardStateById={updateCardStateById}
      />
    </LoadingSkeleton>
  );
};

export default BankCardList;
