import React from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import { ContextForm, formatNumber } from "cerulean";
import CardSelector from "./CardSelector";
import LoanAmountField from "../../../LoanAmountField";

const PayByCardForm = ({ data, cards, setCards, loanPayByCardFee }) => {
  const { l10n } = useLocalization();
  return (
    <>
      <ContextForm.Field required>
        <CardSelector field="card" cards={cards} setCards={setCards} />
      </ContextForm.Field>
      <LoanAmountField data={data} />
      <div className="margin--top--m fontSize--s fontColor--secondary">
        {Number.isNaN(loanPayByCardFee) || loanPayByCardFee === 0
          ? l10n.getString("loan-paybycard-debit-card-disclosure")
          : l10n.getString("loan-paybycard-debit-card-with-fee-disclosure", {
              amount: formatNumber(loanPayByCardFee),
            })}
      </div>
    </>
  );
};

PayByCardForm.propTypes = {
  data: PropTypes.object,
  cards: PropTypes.array,
  setCards: PropTypes.func,
  loanPayByCardFee: PropTypes.number,
};

export default PayByCardForm;
