import type { NetworkPayload } from "../../../composites";

export const API = {
  fetchInternalUserSession: (): NetworkPayload => {
    return {
      url: "account_opening/session/internal_user/",
      options: {
        method: "POST",
        payload: {},
      },
    };
  },
};
