import {
  ContentCard,
  Button,
  Alert,
  useBreakpoints,
  catcat as cc,
} from "cerulean";
import { useLocalization } from "@fluent/react";

import CardImage from "../CardImage";
import styles from "./BankCard.module.scss";

interface BankCardProps {
  textColor: string;
  isLocked: boolean;
  image: string;
  name: string;
  cardId: API.CardId;
  lastFourDigits: string;
  network: API.CardProvider;
  state: API.CardState;
  onManageClick?: () => void;
}

interface ManageButtonProps {
  onManageClick?: () => void;
}

const ManageButton = ({ onManageClick }: ManageButtonProps) => (
  <div className={styles.manageButton}>
    <Button kind="plain" ariaLabel="manage your card" onClick={onManageClick}>
      Manage
    </Button>
  </div>
);

interface InfoBannerProps {
  state: API.CardState;
}

const InfoBanner = ({ state }: InfoBannerProps) => {
  const { l10n } = useLocalization();
  const getLabel = () => {
    switch (state) {
      case "shipped":
        return l10n.getString("card-controls-shipping-description");
      case "damaged":
        return l10n.getString("card-controls-damaged-description");
      default:
        return null;
    }
  };

  if (!["damaged", "shipped"].includes(state)) {
    return null;
  }

  return (
    <div
      className={cc(styles.cardInfoBanner, {
        [styles.shipped]: state === "shipped",
        [styles.damaged]: state === "damaged",
      })}
    >
      <Alert isActive={true} isDismissable={false} kind="info">
        {getLabel()}
      </Alert>
    </div>
  );
};

const BankCard = ({
  textColor,
  isLocked,
  image,
  name,
  cardId,
  lastFourDigits,
  network,
  state,
  onManageClick,
}: BankCardProps) => {
  const { s, m, l, xl } = useBreakpoints();
  const sOrLess = (s && !m) || (!s && !m && !l && !xl);

  return (
    <ContentCard kind="elevated">
      <div className={styles.card}>
        <div className={styles.cardMainContent}>
          <div style={{ width: 150 }}>
            <CardImage
              textColor={textColor}
              isLocked={isLocked}
              image={image}
              cardId={cardId}
              lastFourDigits={lastFourDigits}
              network={network}
            />
          </div>
          <div className={styles.cardDetails}>
            <div className={styles.cardNameAndManageButton}>
              <div className={cc([styles.cardName, "fontWeight--semibold"])}>
                {name}
              </div>
              {m && <ManageButton onManageClick={onManageClick} />}
            </div>
            <div>** {lastFourDigits}</div>
            {sOrLess && <ManageButton onManageClick={onManageClick} />}
            {m && <InfoBanner state={state} />}
          </div>
        </div>
        {sOrLess && <InfoBanner state={state} />}
      </div>
    </ContentCard>
  );
};

export default BankCard;
