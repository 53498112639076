import type { RootState } from "../../entities";

export const selectACHPaymentById = (
  state: RootState,
  id: API.ACHPaymentId,
) => {
  const achValues = Object.values(state.achPayments.byId);

  const ach = achValues.find((a) => {
    return a.id === id;
  });

  return ach;
};
