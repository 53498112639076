import { useEffect, useMemo } from "react";

import { useRequestWithFeedback } from "../../../composites/request/request.hooks";
import { useObjectMemo } from "../../../hooks/useObjectMemo.hook";

import { API } from "./marketing.api";

import type { MarketingLinkEnum } from "./types";
import type { ApiError } from "../../../../types/models/v1/Error.types";

type TargetResult<T> = {
  loading: boolean;
} & (
  | {
      config: T;
      linkUrl: string;
      getResolvedLinkUrl: () => Promise<string | undefined>;
    }
  | {
      config: null;
      linkUrl: null;
      getResolvedLinkUrl: null;
    }
);
/**
 * Return the associated config for a marketing target, most commonly used for native marketing targets.
 *
 * @param address A unique string literal representing the address of the marketing target.
 * @param debugData A debug data object to be returned for demo and testing purposes. This will short-circuit the request and return the data immediately.
 * @returns A promise that resolves to the marketing target config object if an ad is to be served. `config` will be `undefined` if no ad is to be served.
 */
export const useMarketingTarget = <T>(
  address: string,
  debugData?: T,
): TargetResult<T> => {
  const { send, loading, data } = useRequestWithFeedback<T, ApiError>();
  const { send: sendLinkUrl } = useRequestWithFeedback<
    { destination: string },
    ApiError
  >();

  const unixTimestamp = useMemo(() => Math.floor(Date.now() / 1000), []);
  const token = useMemo(() => Math.random().toString(36).substring(2), []);

  useEffect(() => {
    send({ action: API.getMarketingTarget(address, token, unixTimestamp) });
  }, [send, address, token, unixTimestamp]);

  const linkUrl = API.getMarketingLink(address, token, unixTimestamp);

  let result: TargetResult<T>;
  if (process.env.NODE_ENV === "development" && debugData) {
    result = {
      loading: false,
      config: debugData,
      linkUrl,
      getResolvedLinkUrl: async () => {
        const response = await sendLinkUrl({
          action: API.getMarketingDestination(address, token, unixTimestamp),
        });
        return response?.destination;
      },
    };
  } else if (loading) {
    result = {
      loading: true,
      config: null,
      linkUrl: null,
      getResolvedLinkUrl: null,
    };
  } else if (
    // if the data is an empty object, there is no ad to serve.
    typeof data === "object" &&
    data !== null &&
    Object.values(data).length === 0
  ) {
    result = {
      loading: false,
      config: null,
      linkUrl: null,
      getResolvedLinkUrl: null,
    };
  } else {
    result = {
      loading: false,
      config: data as T,
      linkUrl,
      getResolvedLinkUrl: async () => {
        const response = await sendLinkUrl({
          action: API.getMarketingDestination(address, token, unixTimestamp),
        });
        return response?.destination;
      },
    };
  }
  return useObjectMemo(result);
};

export const useMarketingImage = (
  address: string,
  debugSize?: { width: number; height: number },
) => {
  const { send } = useRequestWithFeedback<
    { destination: string; link_enum?: MarketingLinkEnum },
    ApiError
  >();

  const unixTimestamp = useMemo(() => Math.floor(Date.now() / 1000), []);
  const token = useMemo(() => Math.random().toString(36).substring(2), []);

  return {
    linkUrl: API.getMarketingLink(address, token, unixTimestamp),
    imageUrl:
      debugSize && process.env.NODE_ENV === "development"
        ? `https://picsum.photos/${debugSize.width}/${debugSize.height}`
        : API.getMarketingImage(address, token, unixTimestamp),
    getResolvedDestinationDetails: async () => {
      const response = await send({
        action: API.getMarketingDestination(address, token, unixTimestamp),
      });
      return {
        destination: response?.destination,
        linkEnum: response?.link_enum,
      };
    },
    segmintIframeUrl: API.getSegmintIframeUrl(),
  };
};
