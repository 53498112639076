import { createDeepEqualSelector } from "../utils";

import type { RootState } from "../store";

const selectRecipients = (state: RootState) => state.recipients.byId;

export const selectSortedRecipients = createDeepEqualSelector(
  selectRecipients,
  (byId) => Object.values(byId).sort((a, b) => a.name.localeCompare(b.name)),
);

export const selectOneRecipient = createDeepEqualSelector(
  [selectRecipients, (_, recipientId?: API.RecipientId) => recipientId],
  (byId, recipientId): API.Recipient | undefined => {
    return recipientId ? byId[recipientId] : undefined;
  },
);
