import creditCardType from "credit-card-type";

import { RESERVED_BANKING_WORD_LIST, PO_BOX_REGEX } from "./constants";

const functions = {
  appendQuerystringParam(url: string, key: string, value: string) {
    const updatedUrl = new URL(url);
    updatedUrl.searchParams.set(key, value);
    return updatedUrl;
  },

  sumObjArrayField<T>(array: Array<T>, fieldName: keyof T) {
    return array.reduce(
      (accum, p) =>
        Number(accum) + (Number(p[fieldName]) ? Number(p[fieldName]) : 0),
      0,
    );
  },

  shardArrayByChunkSize<T>(input: Array<T>, chunkSize: number) {
    const chunks = [];
    const items = input.slice();
    while (items.length) {
      chunks.push(items.splice(0, chunkSize));
    }
    return chunks;
  },

  //  in lieu of lodash or another utility, use this to deep copy a serializable object
  deepCopy<T>(originalObj: T) {
    return JSON.parse(JSON.stringify(originalObj));
  },

  // This function is incompatible with atlas, hence the comments
  preventBrowserBack() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof window !== "undefined") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.history.pushState({}, "", window.location.pathname);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.onpopstate = function () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.history.pushState({}, "", window.location.pathname);
      };
    }
  },

  phoneFormatter(value: string) {
    if (value === null || value === undefined || value.length === 0) {
      return "";
    }
    const numerical = value.replace(/[^0-9]/g, "");
    const { length } = numerical;
    // formatting area code
    if (length < 4) {
      if (numerical[0] === "1" && length === 1) {
        return "";
      }
      return `(${numerical.substring(0, length)})`;
    }
    // formatting the next three digits along with area code
    if (length < 7) {
      // Checks to see if country code is included
      if (numerical[0] === "1") {
        return `(${numerical.substring(1, 4)}) ${numerical.substring(
          4,
          length,
        )}`;
      }
      return `(${numerical.substring(0, 3)}) ${numerical.substring(3, length)}`;
    }
    // formatting the full phone number
    if (length < 12) {
      // Checks to see if country code is included
      if (numerical[0] === "1") {
        return `(${numerical.substring(1, 4)}) ${numerical.substring(
          4,
          7,
        )}-${numerical.substring(7, length)}`;
      }
    }
    // Checks to see if country code is included
    if (numerical[0] === "1") {
      return `(${numerical.substring(1, 4)}) ${numerical.substring(
        4,
        7,
      )}-${numerical.substring(7, 11)}`;
    }
    return `(${numerical.substring(0, 3)}) ${numerical.substring(
      3,
      6,
    )}-${numerical.substring(6, 10)}`;
  },

  dollarsToPennies(amount: Dollars | number | string) {
    const dollarAmount = Number(String(amount).replace(/[$,]/g, ""));
    return Math.round(dollarAmount * 100) as Cents;
  },

  phoneUnformatter(value: string) {
    // strips all punctuation
    if (value === null || value === undefined || value.length === 0) {
      return "";
    }
    const numerical = value.replace(/[^0-9]/g, "");

    if (numerical.length === 10) {
      // if there are 10 digits and the first is not a 1
      // add country code
      return `+1${numerical}`;
    }

    if (numerical.length > 10) {
      return `+${numerical}`;
    }

    return numerical;
  },

  escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escapes the following chars: \ ^ $ * + ? . ( ) | { } [ ]
  },

  getSignedUrl(string: string) {
    // if we determine that something is a signed_url, return that url
    if (!string || !string.match) return null;
    const matches = string.match(/signed_urls\/.+\/.+/);
    if (!matches) return null;
    return matches[0];
  },

  ndsIconPrepend(string: string) {
    if (!string) return string;
    return string.includes("narmi-icon-") ? string : `narmi-icon-${string}`;
  },

  arrayContainsDuplicates<T>(array: Array<T>) {
    return !!array.filter((a, index) => array.indexOf(a) !== index).length;
  },

  // amount values at different times can be: '$', '1', '1.00' '$1.00, or '$1,000.00'
  // copied over from azul
  parseValueAsFloat(value: string | number) {
    // cover falsy values just in case
    if (!value) return 0;
    if (typeof value === "number") return value;
    return parseFloat(value.replace(/[^\d.]/g, "")) || 0;
  },

  // Given a string, format banking words from a constant banking word list
  formatBankingWords(string: string) {
    let newString = string;
    RESERVED_BANKING_WORD_LIST.forEach((word) => {
      newString = newString.replace(new RegExp(`\\b${word}\\b`, "gi"), word);
    });
    return newString;
  },

  // Given a date string with a dob, return age
  getAge(dob: string) {
    if (!dob) return undefined;
    const today = new Date();
    const birthDate = new Date(dob);
    const age = today.getUTCFullYear() - birthDate.getUTCFullYear();
    const month = today.getUTCMonth() - birthDate.getUTCMonth();
    if (
      month < 0 ||
      (month === 0 && today.getUTCDate() < birthDate.getUTCDate())
    ) {
      return age - 1;
    }
    return age;
  },
  isApplicantOverIraCatchupEligibilityAge(dob: string) {
    if (!dob) return false;
    const IRA_CATCHUP_ELIGIBILITY_AGE = 50;
    return (
      new Date().getFullYear() - new Date(dob).getFullYear() >
      IRA_CATCHUP_ELIGIBILITY_AGE
    );
  },
  validateStreetAddress(streetAddress: string, allowPOBox = false) {
    if (!streetAddress) {
      return "Must be filled in";
    }
    const pattern = PO_BOX_REGEX;
    if (pattern.exec(streetAddress) !== null && !allowPOBox) {
      return "Address must not be a P.O. Box";
    }
    return undefined;
  },
  validateStreetAddressTwo(streetAddressTwo: string, allowPOBox = false) {
    const pattern =
      /\b(?:!?(p.?s*o.?)|pos(t|tal)\s+office)(\s+)?(?:box|[0-9]*)?\b/i;
    if (pattern.exec(streetAddressTwo) !== null && !allowPOBox) {
      return "Line 2 must not be a P.O. Box";
    }
    return undefined;
  },
  isPossiblyAnAmexCardNumber(val: string) {
    const possibleCardTypes = creditCardType(val);

    if (
      possibleCardTypes.length === 1 &&
      possibleCardTypes[0].type === "american-express"
    ) {
      return true;
    }

    return /^3[47]\d{0,13}$/.test(val);
  },
  isPossiblyADinersClubCardNumber(val: string) {
    const digitsOnly = val.replace(/\D/g, "");

    // Diners Club International: valid prefixes (30,36,38,39), length 14–19
    if (/^(30|36|38|39)/.test(digitsOnly)) {
      // If less than 14 digits, it might still become a valid card as user types more.
      if (digitsOnly.length < 14) {
        return true;
      }
      // If between 14 and 19, it's valid now
      if (digitsOnly.length >= 14 && digitsOnly.length <= 19) {
        return true;
      }

      return false;
    }

    // Diners Club US & Canada: prefix 55, length 16
    if (/^55/.test(digitsOnly)) {
      // If length less than 16, it's still possibly a DC card
      if (digitsOnly.length < 16) {
        return true;
      }
      // If exactly 16, it's fully valid
      if (digitsOnly.length === 16) {
        return true;
      }
      return false;
    }

    // No match if no recognized prefix
    return false;
  },
  /* https://medium.com/hootsuite-engineering/a-comprehensive-guide-to-validating-and-formatting-credit-cards-b9fa63ec7863 */
  formatCardNumber(initialValue: string) {
    // remove all non digit characters
    const value = initialValue.replace(/\D/g, "");
    let formattedValue;
    // american express, 15 digits
    if (this.isPossiblyAnAmexCardNumber(value)) {
      formattedValue = value
        .replace(/(\d{4})/, "$1 ")
        .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
    } else if (this.isPossiblyADinersClubCardNumber(value)) {
      // diner's club, 14 digits
      formattedValue = value
        .replace(/(\d{4})/, "$1 ")
        .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
    } else {
      formattedValue = value
        .replace(/(\d{4})/, "$1 ")
        .replace(/(\d{4}) (\d{4})/, "$1 $2 ")
        .replace(/(\d{4}) (\d{4}) (\d{4})/, "$1 $2 $3 ");
    }
    return formattedValue;
  },
};

export default functions;
