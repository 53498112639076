/* eslint-disable camelcase */
import { createSlice } from "@reduxjs/toolkit";

import User from "../../../../User";
import { createDeepEqualSelector } from "../utils";
import { selectUser } from "../selectors";

import type {
  WireDualApproval,
  DualApprovalSlice,
  StatusType,
  UnknownDualApproval,
  PendingWireDualApproval,
  RespondedWireDualApproval,
  AnyDualApproval,
  ACHDualApproval,
  PendingACHDualApproval,
  RespondedACHDualApproval,
  RespondedWireOrACHDualApproval,
  PendingWireOrACHDualApproval,
} from "./types";
import type {
  DualApprovalPayload,
  DualApprovalsPayload,
} from "../../../../types/models/v1";
import type { RootState } from "../store";

const initialState: DualApprovalSlice = {
  byId: {},
};

export const isWireDualApproval = (
  da: API.AnyDualApproval,
): da is API.WireDualApproval => {
  return "wire" in da && !!da.wire;
};

export const isACHDualApproval = (
  da: API.AnyDualApproval,
): da is API.ACHDualApproval => {
  return "ach_payment" in da && !!da.ach_payment;
};

export const isStoreWireDualApproval = (
  da: AnyDualApproval,
): da is WireDualApproval => {
  return "wire" in da && !!da.wire;
};

const isSingular = <
  T extends { approval_request: API.AnyDualApproval },
  TS extends { approval_requests: API.AnyDualApproval[] },
>(
  payload: T | TS,
): payload is T => "approval_request" in payload;

const prepare = (approvalData: DualApprovalPayload | DualApprovalsPayload) => {
  if (isSingular(approvalData)) {
    return {
      payload: {
        dualApproval: approvalData.approval_request,
      },
    };
  }
  return {
    payload: {
      dualApprovals: approvalData.approval_requests,
    },
  };
};

export const getStatusForWire = (da: API.WireDualApproval): StatusType => {
  const { wire } = da;
  if (wire.state === "rejected_approval") {
    return "rejected";
  }
  if (wire.state === "awaiting_approval") {
    return "pending";
  }
  return "approved";
};

export const getStatusForACH = (da: API.ACHDualApproval): StatusType => {
  const { ach_payment } = da;
  if (ach_payment.state === "rejected_approval") {
    return "rejected";
  }
  if (ach_payment.state === "awaiting_approval") {
    return "pending";
  }
  return "approved";
};

const receiveDualApproval = (
  state: DualApprovalSlice,
  da: API.AnyDualApproval,
) => {
  const { uuid, created_at, requester, responder } = da;
  let status: StatusType = "unknown";

  if (isWireDualApproval(da)) {
    status = getStatusForWire(da);
  }

  if (isACHDualApproval(da)) {
    status = getStatusForACH(da);
  }
  // eslint-disable-next-line no-param-reassign
  state.byId[da.uuid] = {
    id: uuid,
    created_at,
    ...(isWireDualApproval(da) && { wire: da.wire.id }),
    ...(isACHDualApproval(da) && { ach: da.ach_payment.id }),
    requester: {
      id: requester.id,
      name: User.deserialize(requester).getDescription(),
    },
    responder: responder
      ? {
          id: responder.id,
          name: User.deserialize(responder).getDescription(),
        }
      : null,
    status,
  } as AnyDualApproval;
};

type ReceiveAction = ReturnType<typeof prepare>;

const dualApprovalSlice = createSlice({
  name: "dualApprovals",
  initialState,
  reducers: {
    receive: {
      prepare,
      reducer: (state, action: ReceiveAction) => {
        if ("dualApprovals" in action.payload) {
          const { dualApprovals } = action.payload;
          dualApprovals?.forEach((da) => {
            receiveDualApproval(state, da);
          });
        } else {
          const { dualApproval } = action.payload;
          receiveDualApproval(state, dualApproval);
        }
      },
    },
  },
});

export type ExtraAction = ReturnType<typeof dualApprovalSlice.actions.receive>;

export const { actions } = dualApprovalSlice;
export default {
  dualApprovals: dualApprovalSlice.reducer,
};

export const sortDualApprovals = <T extends { created_at: string }>(
  a: T,
  b: T,
) => {
  if (a.created_at < b.created_at) {
    return 1;
  }
  if (a.created_at > b.created_at) {
    return -1;
  }

  return 0;
};

const isWireFilter = (da: UnknownDualApproval): da is WireDualApproval => {
  return "wire" in da && !!da.wire;
};

const isACHFilter = (da: UnknownDualApproval): da is ACHDualApproval => {
  return "ach" in da && !!da.ach;
};

export const selectDualApprovalForWire = <
  S extends { dualApprovals: DualApprovalSlice },
>(
  state: S,
  wireId: API.WireId,
) => {
  const dualApprovalValues = Object.values(state.dualApprovals.byId).filter(
    isWireFilter,
  );

  const dualApproval = dualApprovalValues.find((da) => {
    return da.wire === wireId;
  });

  return dualApproval;
};

export const selectDualApprovalForACH = <
  S extends { dualApprovals: DualApprovalSlice },
>(
  state: S,
  achId: API.ACHPaymentId,
) => {
  const dualApprovalValues = Object.values(state.dualApprovals.byId).filter(
    isACHFilter,
  );

  const dualApproval = dualApprovalValues.find((da) => {
    return da.ach === achId;
  });

  return dualApproval;
};

export const selectDualApproval = (
  state: RootState,
  id: API.DualApprovalId,
) => {
  return state.dualApprovals.byId[id];
};

export const selectWireDualApprovals = <
  S extends { dualApprovals: DualApprovalSlice },
>(
  state: S,
) => {
  const dualApprovalValues = Object.values(state.dualApprovals.byId);

  const filtered = dualApprovalValues
    .filter(isWireFilter)
    .sort(sortDualApprovals);

  return filtered;
};

export const selectACHDualApprovals = <
  S extends { dualApprovals: DualApprovalSlice },
>(
  state: S,
) => {
  const dualApprovalValues = Object.values(state.dualApprovals.byId);

  const filtered = dualApprovalValues
    .filter(isACHFilter)
    .sort(sortDualApprovals);

  return filtered;
};

export const selectAllDualApprovals = <
  S extends { dualApprovals: DualApprovalSlice },
>(
  state: S,
) => {
  const dualApprovalValues = Object.values(state.dualApprovals.byId);

  const sorted = dualApprovalValues.sort(sortDualApprovals);

  return sorted;
};

export const selectPendingDualApprovalsWires = createDeepEqualSelector(
  selectWireDualApprovals,
  (wireDualApprovals): PendingWireDualApproval[] => {
    const filtered = wireDualApprovals
      .filter((da): da is PendingWireDualApproval => {
        return da.responder === null;
      })
      .sort(sortDualApprovals);
    return filtered;
  },
);

export const selectPendingDualApprovalsACH = createDeepEqualSelector(
  selectACHDualApprovals,
  (achDualApprovals) => {
    const filtered = achDualApprovals
      .filter((da): da is PendingACHDualApproval => {
        return da.responder === null;
      })
      .sort(sortDualApprovals);
    return filtered;
  },
);

export const selectAllPendingDualApprovalsWireOrACH = createDeepEqualSelector(
  [selectPendingDualApprovalsWires, selectPendingDualApprovalsACH],
  (wireDAs, achDAs) => {
    const combined: PendingWireOrACHDualApproval[] = [...wireDAs, ...achDAs];
    const filtered = combined.sort(sortDualApprovals);
    return filtered;
  },
);

export const selectRespondedDualApprovalsWires = createDeepEqualSelector(
  selectWireDualApprovals,
  (wireDualApprovals) => {
    const filtered = wireDualApprovals
      .filter((da): da is RespondedWireDualApproval => {
        return da.responder !== null;
      })
      .sort(sortDualApprovals);

    return filtered;
  },
);

export const selectRespondedDualApprovalsACH = createDeepEqualSelector(
  selectACHDualApprovals,
  (achDualApprovals) => {
    const filtered = achDualApprovals
      .filter((da): da is RespondedACHDualApproval => {
        return da.responder !== null;
      })
      .sort(sortDualApprovals);

    return filtered;
  },
);

export const selectAllRespondedDualApprovalsWireOrACH = createDeepEqualSelector(
  [selectRespondedDualApprovalsWires, selectRespondedDualApprovalsACH],
  (wireDAs, achDAs) => {
    const combined: RespondedWireOrACHDualApproval[] = [...wireDAs, ...achDAs];
    const filtered = combined.sort(sortDualApprovals);
    return filtered;
  },
);

export const selectWireDualApprovalsByStatus = createDeepEqualSelector(
  [selectWireDualApprovals, (_state, status: StatusType) => status],
  (wireDualApprovals, status) => {
    return wireDualApprovals.filter((da) => da.status === status);
  },
);

export const selectACHDualApprovalsByStatus = createDeepEqualSelector(
  [selectACHDualApprovals, (_state, status: StatusType) => status],
  (achDualApprovals, status) => {
    return achDualApprovals.filter((da) => da.status === status);
  },
);

export const selectIsDualApprovalRequired = createDeepEqualSelector(
  selectUser,
  (user) => {
    if (user === null || user === undefined) return false;
    return user.dual_approval_required || false;
  },
);
