import { Link } from "react-router-dom";
import { Button } from "cerulean";

const BackButton = ({
  to,
  label = "Back",
  onClick,
}: {
  to: string;
  label?: string;
  onClick?: () => void;
}) => (
  <Link to={to}>
    <Button type="button" kind="plain" onClick={onClick}>
      {label}
    </Button>
  </Link>
);

export default BackButton;
