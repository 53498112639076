import { useState, useCallback, useMemo } from "react";

import { useSpring, animated } from "@react-spring/web";
import { catcat as cc, Tag, Tooltip } from "cerulean";
import { entities } from "byzantine";
import Utils from "byzantine/src/utils";
import { format } from "date-fns";

import { useInstitutionSettings } from "../../../contexts/InstitutionSettingsContext";
import { useSudoContext } from "../../../SudoContext";
import VISA_LOGO from "../../../../assets/images/visa_logo_official.svg";
import MASTERCARD_LOGO from "../../../../assets/images/mastercard_logo_unofficial.svg";

import styles from "./CardImage.module.scss";

type CardSize = "x-small" | "small" | "large";

interface CardImageProps {
  name?: string;
  textColor: string;
  isLocked: boolean;
  image: string;
  cardId: API.CardId;
  lastFourDigits?: string;
  network: API.CardProvider;
  size?: CardSize;
  showSensitiveInfoButton?: boolean;
}

const CARD_LOGOS = {
  Mastercard: MASTERCARD_LOGO,
  Visa: VISA_LOGO,
} as const;

const sizeConfig = {
  "x-small": {
    mastercard: { bottom: "5px", right: "0px" },
    visa: { bottom: "7.53px", right: "8px" },
    logoSize: "20px",
  },
  small: {
    mastercard: { bottom: "7px", right: "0px" },
    visa: { bottom: "11.65px", right: "12px" },
    logoSize: "32px",
  },
  large: {
    mastercard: { bottom: "8px", right: "0px" },
    visa: { bottom: "18.01px", right: "20px" },
    logoSize: "68px",
  },
} as const;

const CARD_ASPECT_RATIOS = {
  Mastercard: 152 / 108,
  Visa: 1920 / 620,
} as const;

interface SensitiveCardInfoToggleButtonProps {
  isFlipped: boolean;
  onClick: () => void;
  textColor: string;
  isDisabled: boolean;
}

const SensitiveCardInfoToggleButton = ({
  textColor,
  isFlipped,
  onClick,
  isDisabled,
}: SensitiveCardInfoToggleButtonProps) => (
  <button
    className={styles.sensitiveCardInfoBtn}
    onClick={onClick}
    aria-label={isFlipped ? "Hide sensitive info" : "Show sensitive info"}
    disabled={isDisabled}
  >
    <span
      className={isFlipped ? "narmi-icon-eye-off" : "narmi-icon-eye"}
      style={{ color: textColor }}
    />
  </button>
);

const useCopyToClipboard = (text: string | null) => {
  const [tooltipText, setTooltipText] = useState("Click to copy");

  const copyText = useCallback(() => {
    if (text) {
      navigator.clipboard.writeText(text);
      setTooltipText("Copied");
    }
  }, [text]);

  const resetTooltip = useCallback(() => {
    setTimeout(() => {
      // Delay resetting tooltip to avoid flickering
      setTooltipText("Click to copy");
    }, 1000);
  }, []);

  return { copyText, resetTooltip, tooltipText };
};

const CardImage = ({
  name,
  textColor,
  isLocked,
  image,
  cardId,
  lastFourDigits,
  network,
  size = "small",
  showSensitiveInfoButton = false,
}: CardImageProps) => {
  // We can't just use "loading" from useSensitiveCardInfo because
  // there needs to be a sudo check before that request even happens.
  // This state will encompass both requests.
  const [isLoadingSensitiveCardInfo, setIsLoadingSensitiveCardInfo] =
    useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isHalfway, setIsHalfway] = useState(false);

  const { sudo_mode_required_for_sensitive_card_info: sudoRequired } =
    useInstitutionSettings();
  const { establishSudo, setOnCancel } = useSudoContext();
  const { data: sensitiveInfo, send: fetchSensitiveCardInfo } =
    entities.sensitiveCardInfo.useSensitiveCardInfo(cardId);

  const onSudoCancel = useCallback(() => {
    setIsLoadingSensitiveCardInfo(false);
  }, []);

  const onSensitiveInfoBtnClick = useCallback(() => {
    const fetchInfo = async () => {
      if (!sensitiveInfo) {
        await fetchSensitiveCardInfo();
      }
      setIsFlipped(true);
      setIsLoadingSensitiveCardInfo(false);
    };

    if (!isFlipped) {
      setIsLoadingSensitiveCardInfo(true);
      if (sudoRequired) {
        setOnCancel(onSudoCancel);
        establishSudo(`/cards/${cardId}`, fetchInfo);
      } else {
        fetchInfo();
      }
    } else {
      setIsFlipped(false);
    }
  }, [
    establishSudo,
    fetchSensitiveCardInfo,
    isFlipped,
    cardId,
    sensitiveInfo,
    sudoRequired,
  ]);

  const { transform } = useSpring({
    progress: isFlipped ? 1 : 0,
    onChange: ({ value: { progress } }: { value: { progress: number } }) => {
      setIsHalfway(progress >= 0.5);
    },
    transform: `rotateY(${isFlipped ? 180 : 0}deg)`,
    config: { mass: 1, tension: 280, friction: 20, duration: 400 },
  });

  const cardNetwork = network in CARD_LOGOS ? network : "Visa";
  const logo = CARD_LOGOS[cardNetwork];

  const aspectRatio = CARD_ASPECT_RATIOS[cardNetwork];
  const sizeSettings = sizeConfig[size];
  const networkSizeSettings =
    sizeSettings[cardNetwork.toLowerCase() as "visa" | "mastercard"];

  const width: `${number}px` = sizeSettings.logoSize;
  const height = `${parseInt(width, 10) / aspectRatio}px`;

  const cardLogoStyle = {
    backgroundImage: `url('${logo}')`,
    width,
    height,
    ...networkSizeSettings,
  };

  const cardSideStyles = {
    color: textColor,
    backgroundImage: `url('${image}')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    filter: isLocked ? "opacity(0.4)" : "",
  };

  const sizeClass = size === "large" ? styles.large : styles.small;
  const cardLogoImg = <div className={styles.cardLogo} style={cardLogoStyle} />;

  const cardNumber = useMemo(() => {
    if (!sensitiveInfo?.pan) {
      return null;
    }
    return Utils.formatCardNumber(sensitiveInfo?.pan);
  }, [sensitiveInfo?.pan]);

  const expirationDate = useMemo(() => {
    if (!sensitiveInfo?.expiration_date) {
      return null;
    }
    return format(new Date(sensitiveInfo?.expiration_date), "MM/yy");
  }, [sensitiveInfo?.expiration_date]);

  const {
    copyText: copyCardNumber,
    resetTooltip,
    tooltipText,
  } = useCopyToClipboard(cardNumber);

  return (
    <div
      className={cc(styles.cardWrapper, { [styles.large]: size === "large" })}
    >
      <animated.div
        className={cc(styles.cardContainer, sizeClass)}
        style={{ transform }}
      >
        {/* Back Side */}
        <div
          className={cc(styles.cardBack, { [styles.hidden]: !isHalfway })}
          style={cardSideStyles}
        >
          {sensitiveInfo && (
            <div className={styles.sensitiveInfo}>
              <div className={styles.infoRow}>
                <div className={styles.info}>
                  <div className={styles.infoLabel}>Card number</div>
                  {cardNumber && (
                    <span
                      role="button"
                      className={styles.copyableCardNumber}
                      onClick={copyCardNumber}
                      onMouseLeave={resetTooltip}
                    >
                      <Tooltip text={tooltipText}>{cardNumber}</Tooltip>
                    </span>
                  )}
                </div>
              </div>
              <div className={styles.infoRow}>
                <div className={styles.info}>
                  <div className={styles.infoLabel}>Expiration</div>
                  <div className={styles.infoValue}>{expirationDate}</div>
                </div>
                <div className={styles.info}>
                  <div className={styles.infoLabel}>CVV</div>
                  <div className={styles.infoValue}>{sensitiveInfo.cvv}</div>
                </div>
                <div className={styles.info}>
                  <SensitiveCardInfoToggleButton
                    isDisabled={isLoadingSensitiveCardInfo}
                    textColor={textColor}
                    isFlipped={isFlipped}
                    onClick={onSensitiveInfoBtnClick}
                  />
                </div>
              </div>
            </div>
          )}
          {cardLogoImg}
        </div>
        {/* Front Side */}
        <div
          className={cc(styles.cardSvg, { [styles.hidden]: isHalfway })}
          style={cardSideStyles}
        >
          {name && <span className={styles.cardName}>{name}</span>}
          {lastFourDigits && (
            <>
              <span className={styles.mask}>**</span>
              <span className={styles.lastFour}>{lastFourDigits}</span>
              {showSensitiveInfoButton && !isLocked && (
                <SensitiveCardInfoToggleButton
                  isDisabled={isLoadingSensitiveCardInfo}
                  textColor={textColor}
                  isFlipped={isFlipped}
                  onClick={onSensitiveInfoBtnClick}
                />
              )}
            </>
          )}
          {cardLogoImg}
        </div>

        {isLocked && (
          <div className={styles.lockedTag}>
            <Tag label="Locked" />
          </div>
        )}
      </animated.div>
    </div>
  );
};

export default CardImage;
