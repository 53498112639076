/* eslint-disable camelcase */

import { useLocalization } from "@fluent/react";
import { modules } from "byzantine";
import Account from "byzantine/src/Account";
import Feature, { Features, featureEquals } from "byzantine/src/Feature";
import { useUserFeatures } from "../../contexts/UserFeaturesContext";
import MemberFriendSection from "./MemberFriendSection";
import PayeeSection from "./PayeeSection";
import WireRecipientsSection from "./WireRecipientsSection";
import RecipientsSection from "./RecipientsSection";

export interface RecipientsProps {
  accounts: Account[];
}

const Recipients = ({ accounts }: RecipientsProps) => {
  const userFeatures = useUserFeatures() as Features;
  const hasManageRecipientPermission =
    modules.achPayments.useManageRecipientPermission();
  const displayedFeatures = [
    ...(userFeatures.bill_pay ? ["Bill Pay"] : []),
    ...(featureEquals(userFeatures, "p2p", "m2m")
      ? [useLocalization().l10n.getString("member-to-member")]
      : []),
    ...(userFeatures.wires ? ["Wire"] : []),
    ...(userFeatures.ach_payments && hasManageRecipientPermission
      ? ["ACH payment"]
      : []),
  ];

  const lastDisplayedFeature = displayedFeatures.pop();
  const commaText = displayedFeatures.join(", ");
  const andText = displayedFeatures.length >= 1 ? " and " : "";
  const featuresListText = `These are your linked recipients for ${commaText}${andText}${lastDisplayedFeature} transfers.`;

  return (
    <div>
      <div className="margin--top--l">{featuresListText}</div>
      <Feature features={userFeatures} flag="bill_pay">
        <PayeeSection />
      </Feature>
      <Feature features={userFeatures} flag="p2p" equals="m2m">
        <MemberFriendSection accounts={accounts} />
      </Feature>
      <Feature features={userFeatures} flag="wires" not="ach_payments">
        <WireRecipientsSection />
      </Feature>
      {hasManageRecipientPermission ? (
        <Feature features={userFeatures} flag="ach_payments">
          <RecipientsSection />
        </Feature>
      ) : null}
    </div>
  );
};

export default Recipients;
