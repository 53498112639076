// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subheaderText--mIhvU{margin-top:var(--space-xxs);margin-bottom:var(--space-l)}.actionBarWrapper--clD6b{margin-top:66px}", "",{"version":3,"sources":["webpack://./components/SudoContext/SubmitOTP.module.scss"],"names":[],"mappings":"AAAA,sBACE,2BAAA,CACA,4BAAA,CAGF,yBACE,eAAA","sourcesContent":[".subheaderText {\n  margin-top: var(--space-xxs);\n  margin-bottom: var(--space-l);\n}\n\n.actionBarWrapper {\n  margin-top: 66px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subheaderText": "subheaderText--mIhvU",
	"actionBarWrapper": "actionBarWrapper--clD6b"
};
export default ___CSS_LOADER_EXPORT___;
