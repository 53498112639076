import { ReactNode } from "react";

import { RenderItemMenuProps } from "./types";

export const isNestedMenuItem = (
  item: API.AnyMenuItem,
): item is API.NestedMenu => "menu" in item;

export const isLinkItem = (item: API.AnyMenuItem): item is API.LinkMenuItem =>
  "url" in item;

export const isAppItem = (item: API.AnyMenuItem): item is API.AppMenuItem =>
  "app" in item;

export const isNafItem = (item: API.AnyMenuItem): item is API.NAFMenuItem =>
  "naf" in item;

export const isAccountItem = (
  item: API.AnyMenuItem,
): item is API.AccountMenuItem => "accounts" in item;

export const isAoiobItem = (item: API.AnyMenuItem): item is API.AOIOBMenuItem =>
  "aoiob" in item;

export const getLabel = ({
  name,
  itemProps,
}: RenderItemMenuProps<API.AnyMenuItem>) => {
  let label: ReactNode = name;

  if ("superscript" in itemProps) {
    label = (
      <>
        {name}
        <sup>{itemProps.superscript}</sup>
      </>
    );
  }

  return label;
};
