import { Dialog, Button, Row } from "cerulean";
import { useLocalization } from "@fluent/react";

type DualApprovalRejectDialog = {
  isOpen: boolean;
  handleClose: () => void;
  handleReject: () => void;
  totalSelected: number;
};

const DualApprovalRejectDialog = ({
  isOpen,
  handleClose,
  handleReject,
  totalSelected,
}: DualApprovalRejectDialog) => {
  const { l10n } = useLocalization();

  const handleClickReject = () => {
    handleReject();
    handleClose();
  };

  return (
    <Dialog
      width="385px"
      isOpen={isOpen}
      onUserDismiss={handleClose}
      title={l10n.getString("reject-modal-heading", { totalSelected })}
      footer={
        <Row alignItems="center">
          <Row.Item>
            <Button kind="plain" onClick={handleClose}>
              Cancel
            </Button>
          </Row.Item>
          <Row.Item shrink>
            <Button onClick={handleClickReject}>
              {l10n.getString("reject-modal-confirm")}
            </Button>
          </Row.Item>
        </Row>
      }
    >
      <span className="margin--bottom--xl">
        {l10n.getString("reject-modal-content", { totalSelected })}
      </span>
    </Dialog>
  );
};

export default DualApprovalRejectDialog;
