import { useEffect, useState } from "react";
import { useLocalization } from "@fluent/react";
import { entities } from "byzantine";
import {
  AccountNumberTextInput,
  RoutingNumberTextInput,
  Select,
} from "cerulean";
import {
  CollapsibleCard,
  ResponsiveFlex,
  Button,
  Row,
} from "@narmi/design_system";
import { SectionProps } from "./RecipientDrawer";
import SectionHeader from "./SectionHeader";
import { useDrawerContext } from "./DrawerValidationsProvider";
import * as formAdapters from "../../../../src/adapters";

const ACHDestinationSection = ({
  isOpen,
  onClose,
  onOpen,
  onSave,
  recipientId,
}: SectionProps) => {
  const { l10n } = useLocalization();
  const accountTypes = entities.recipients.useAccountTypeOptions();
  const recipient = entities.recipients.useRecipient(recipientId);
  const { form, handleSubmit, loading } =
    entities.recipients.useACHDestinationForm(recipientId);
  const { triggerValidations, validNotSavedSections } = useDrawerContext();
  const [showCancelButton, setShowCancelButton] = useState(false);

  useEffect(() => {
    if (recipient?.ach_destination?.id) {
      setShowCancelButton(true);
    }
  }, []);

  const handleClickSave = () => {
    const onSuccess = () => {
      setShowCancelButton(true);
      // we have to "reset" the form so the form reset's dirty value after being saved
      form.resetForm({ values: form.values });
      onSave();
    };
    handleSubmit(onSuccess);
  };

  const handleClickOpen = () => {
    const { isValid: isDrawerValid } = triggerValidations();
    if (!isDrawerValid) {
      return;
    }
    onOpen();
  };

  const handleClickClose = () => {
    const { isValid: isDrawerValid } = triggerValidations();
    if (!isDrawerValid) {
      return;
    }
    onClose();
  };

  const handleClickCancel = () => {
    form.resetForm();
    onClose();
  };

  return (
    <CollapsibleCard
      onClose={handleClickClose}
      onOpen={handleClickOpen}
      isOpen={isOpen}
      trigger="header"
      renderTitle={() => (
        <SectionHeader
          title="ACH"
          isOpen={isOpen}
          checkCondition={Boolean(form.values.accountNumber)}
        />
      )}
    >
      <ResponsiveFlex gapSize="s">
        {validNotSavedSections.ach ? (
          <div className="message-content negative">
            <span className="narmi-icon-info margin--right--m" />
            Save details to continue.
          </div>
        ) : null}
        <AccountNumberTextInput
          label={l10n.getString("labelAccountNumber", null, "Account number")}
          {...formAdapters.fieldWithOnChange(form, "accountNumber")}
        />

        <RoutingNumberTextInput
          label={l10n.getString("label-routing", null, "Routing number")}
          {...formAdapters.fieldWithOnChange(form, "routingNumber")}
        />

        <Select
          label={l10n.getString(
            "ach-payment-account-type",
            null,
            "Account type",
          )}
          {...formAdapters.select(form, "accountType")}
        >
          {accountTypes.map((option, index) => (
            <Select.Item key={`account_type_${index}`} value={option}>
              {option}
            </Select.Item>
          ))}
        </Select>
        <Row alignItems="center">
          <Row.Item>
            {showCancelButton ? (
              <Button kind="negative" onClick={handleClickCancel}>
                {l10n.getString("button-cancel")}
              </Button>
            ) : null}
          </Row.Item>
          <Row.Item shrink>
            <Button
              kind="primary"
              onClick={handleClickSave}
              disabled={loading}
              isLoading={loading}
            >
              {l10n.getString("button-save")}
            </Button>
          </Row.Item>
        </Row>
      </ResponsiveFlex>
    </CollapsibleCard>
  );
};

export default ACHDestinationSection;
