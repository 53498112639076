import { useLocalization } from "@fluent/react";
import { Button } from "@narmi/design_system";
import { modules } from "byzantine";

const AOIOBItem = () => {
  const { l10n } = useLocalization();

  const label = l10n.getString(
    "tools-menu-open-an-account",
    {},
    "Open an account",
  );

  const { fetchAoiobLink } = modules.aoiob.useFetchAoiobLink();

  return (
    <Button
      as="a"
      href={undefined}
      onClick={() => {
        fetchAoiobLink({
          onSuccess: ({ link }: { link: string }) => {
            window.open(link, "_blank");
          },
        });
      }}
      kind="menu"
      label={label}
      rel="noreferrer"
      target="_blank"
    />
  );
};

export default AOIOBItem;
