import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ApiHttp from "byzantine/src/ApiHttp";
import {
  Button,
  ContextForm,
  TextInput,
  useFormData,
  useLoadingContext,
  catcat as cc,
} from "cerulean";

import HeaderWithSteps from "../HeaderWithSteps";
import { fetchSudoCode } from "./utils";
import Container from "../Container";
import BackButton from "./BackButton";
import ActionBar from "./ActionBar";
import { useSudoContext, NUMBER_OF_SUDO_STEPS } from "./SudoContext";

import styles from "./SubmitOTP.module.scss";
import buttonStyles from "./ActionBar.module.scss";

interface SubmitOTPProps {
  isInDialog?: boolean;
}

const SubmitOTP = ({ isInDialog }: SubmitOTPProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedDeviceId, helpMessage: initialHelpMessage } =
    location.state || {};

  const [helpMessage, setHelpMessage] = useState(initialHelpMessage?.message);
  const { destinationRoute, totalSteps, requestOtpRoute, onEstablishedSudo } =
    useSudoContext();
  const { setIsLoading } = useLoadingContext() as {
    setIsLoading: (state: boolean) => void;
  };
  const { formData, onChange } = useFormData();

  const sendCode = (callback: (error?: object) => void) => {
    ApiHttp.fetch(
      "sudo",
      { method: "POST" },
      { one_time_password: formData.one_time_password },
    )
      .then(() => {
        if (!onEstablishedSudo) {
          navigate(destinationRoute || "/");
        } else {
          onEstablishedSudo().finally(() => {
            navigate(destinationRoute || "/");
          });
        }
      })
      .catch(() => {
        callback({
          one_time_password:
            "Incorrect verification code entered, please try again.",
        });
      });
  };

  const requestCodeAgain = async () => {
    setIsLoading(true);
    const response = await fetchSudoCode(selectedDeviceId);
    if (response?.message) {
      setHelpMessage(response.message);
    }
    setIsLoading(false);
  };

  const subheader = (
    /*
    For most flows that use HeaderWithSteps, spacing b/t header and subheader is 8px on l/m, 4px on s/xs.
    For sudo, it's always 4px, so we need to subtract 4px here
    Also, we only want 20px of margin b/t the resend code button and the content (vs. the typical 40px)
    */
    <div style={{ marginTop: "-4px", marginBottom: "-20px" }}>
      {helpMessage || "A verification code was just sent to your device."}
      <div
        className={cc("margin--top--xs", {
          "padding--bottom--l": isInDialog,
        })}
      >
        <Button
          kind="plain"
          onClick={requestCodeAgain}
          label="Resend code"
          size="s"
        />
      </div>
    </div>
  );

  const content = (
    <ContextForm data={formData} onChange={onChange} formID="submit-otp">
      <ContextForm.Field required>
        <TextInput field="one_time_password" label="Verification code" />
      </ContextForm.Field>
      <div className={cc({ [styles.actionBarWrapper]: isInDialog })}>
        <ActionBar align={isInDialog ? "right" : "center"}>
          <BackButton to={requestOtpRoute} />
          <div className={buttonStyles.continueButtonWrapper}>
            <ContextForm.Action
              onSubmit={sendCode}
              dangerouslyDisableShowLoading
            >
              <Button type="submit" label="Continue" />
            </ContextForm.Action>
          </div>
        </ActionBar>
      </div>
    </ContextForm>
  );

  return isInDialog ? (
    <>
      <div className={styles.subheaderText}>{subheader}</div>
      {content}
    </>
  ) : (
    <Container>
      <HeaderWithSteps
        step={totalSteps ? totalSteps - NUMBER_OF_SUDO_STEPS : null}
        totalSteps={totalSteps}
        headerText="Enter verification code"
        subheaderText={subheader}
      />
      {content}
    </Container>
  );
};

export default SubmitOTP;
