import React, { useState } from "react";
import PropTypes from "prop-types";
import cc from "classcat";
import { TextInput, Button, RadioButtons, Tooltip } from "@narmi/design_system";
import Payee from "byzantine/src/Payee";
import Address from "byzantine/src/Address";
import {
  ContextForm,
  Dialog,
  PhoneTextInput,
  useFormData,
  EmailFormField,
  PasswordTextInput,
} from "cerulean";
import UserFeaturesContext from "../contexts/UserFeaturesContext";
import CountrySelector from "../form/CountrySelector";
import BankingUSAddressField from "../address/BankingUSAddressField";

const AddPayeeModal = ({ updatePayees, handleClose, ...props }) => {
  const { formData, setFormData, onChange } = useFormData({});
  const [payeeType, setPayeeType] = useState(Payee.TYPE.BUSINESS);
  const { disable_billpay_create_person_payee } =
    React.useContext(UserFeaturesContext);

  const payeeCreateOptions = { Business: Payee.TYPE.BUSINESS };
  if (!disable_billpay_create_person_payee) {
    payeeCreateOptions.Personal = Payee.TYPE.PERSON;
  }

  const onSubmit = (callback) => {
    const payload = {
      type: payeeType,
      name: formData.name,
      description: formData.description,
      phone: formData.phone,
      email: formData.email,
      passphrase: formData.passphrase,
      address: {
        street_address: formData.street_address,
        street_address_2: formData.street_address_2,
        city: formData.city,
        region_code: formData.region_code,
        postal_code: formData.postal_code,
        country_code: formData.country_code,
      },
      account_number: formData.account_number,
      ignore_warnings: formData.ignore_warnings,
    };
    // We only want to validate the address if the payee is a business
    if (payload.type !== Payee.TYPE.PERSON) {
      const address = new Address(payload.address);
      try {
        address.validate();
      } catch (error) {
        callback("Please enter a complete address.");
        return;
      }
    }
    const newPayee = new Payee(payload);
    newPayee
      .submit()
      .then((response) => {
        if (response.payee) {
          const deserializedPayee = Payee.deserialize(response.payee);
          updatePayees(deserializedPayee);
          callback();
          handleClose();
          // we want to reset the form if payee add is successful
          setFormData({});
          setPayeeType("");
        }
      })
      .catch(callback);
  };

  const descriptionLabel = () => {
    if (payeeType === Payee.TYPE.PERSON) {
      return "Nickname";
    }
    return "Description (e.g. utilities)";
  };

  return (
    <Dialog
      title=""
      isOpen={props.open}
      onUserDismiss={handleClose}
      headerStyle="plain"
    >
      <ContextForm
        nativeForm={false}
        data={formData}
        onChange={onChange}
        confirmWithToastMessage={true}
      >
        <div className="form-section-header">Payee type</div>
        <div className="payee-select-container">
          <RadioButtons
            initialValue={Payee.TYPE.BUSINESS}
            name="payeeType"
            onChange={(e) => setPayeeType(e.target.value)}
            value={payeeType}
            options={payeeCreateOptions}
          />
        </div>
        <div
          className={cc([
            "form-section-header",
            "margin--top--l",
            { "margin--bottom--xs": payeeType !== Payee.TYPE.PERSON },
          ])}
        >
          Payee details
        </div>
        {payeeType !== Payee.TYPE.PERSON && (
          <div className="fontSize--s fontColor--secondary margin--bottom--l">
            Make sure to enter the correct merchant account number and zip code.
            Incorrect entries will result in payment by physical check.
          </div>
        )}
        <ContextForm.Field required>
          <TextInput field="name" label="Name" />
        </ContextForm.Field>
        <ContextForm.Field required>
          <TextInput field="description" label={descriptionLabel()} />
        </ContextForm.Field>
        {payeeType !== Payee.TYPE.PERSON ? (
          <div className="has-tooltip-child">
            <ContextForm.Field required>
              <TextInput field="account_number" label="Account number" />
              <div className="tooltip-info-icon">
                <Tooltip
                  text={
                    "If you don’t have an account number, enter your customer ID"
                  }
                >
                  <span className="narmi-icon-info"></span>
                </Tooltip>
              </div>
            </ContextForm.Field>
          </div>
        ) : (
          <EmailFormField required />
        )}
        <ContextForm.Field required>
          <PhoneTextInput field="phone" />
        </ContextForm.Field>
        {payeeType !== Payee.TYPE.PERSON && (
          <>
            <BankingUSAddressField
              onUpdate={onChange}
              data={formData || {}}
              onChange={onChange}
              showTitle={false}
            />
            <div className="margin--top--l">
              <ContextForm.Field required>
                <CountrySelector
                  label="Country"
                  field="country_code"
                  countries={Payee.GEO_COUNTRIES}
                />
              </ContextForm.Field>
            </div>
          </>
        )}
        {payeeType === Payee.TYPE.PERSON && (
          <React.Fragment>
            <div className="form-section-header margin--bottom--xs">
              Secret phrase
            </div>
            <div style={{ fontSize: "16px", marginBottom: "20px" }}>
              Create a secret phrase that your payee will enter in order to
              receive your payment. After submitting your payment, please share
              this phrase with your payee.
            </div>
            <ContextForm.Field style={{ marginBottom: 0 }} required>
              <PasswordTextInput
                field="passphrase"
                name="passphrase"
                label="Secret phrase"
              />
            </ContextForm.Field>
          </React.Fragment>
        )}
        <div
          style={{
            marginTop: "40px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={() => {
              handleClose();
            }}
            style={{
              paddingRight: "16px",
            }}
            kind="negative"
            label="Cancel"
            type="button"
          />
          <ContextForm.Action
            onSubmit={onSubmit}
            warningCta="Please verify the address. Override is required to complete the action. Click the 'Add payee' button below to proceed anyway."
          >
            <Button label="Add payee" />
          </ContextForm.Action>
        </div>
      </ContextForm>
    </Dialog>
  );
};

AddPayeeModal.propTypes = {
  setModalOpen: PropTypes.func,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  updatePayees: PropTypes.func,
};

export default AddPayeeModal;
