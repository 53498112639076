import { useState } from "react";
import { Table } from "semantic-ui-react";
import { useLocalization } from "@fluent/react";
import { Tag } from "@narmi/design_system";
import Filters from "byzantine/src/filters";
import DualApprovalRequest from "byzantine/src/DualApprovalRequest";
import styles from "./DualApproval.module.scss";
import DualApprovalDrawer from "./DualApprovalDrawer";

type DualApprovalHistoryTableType = {
  dualApprovalRequests: DualApprovalRequest[];
};

export const mapDualApprovalStatus = (state: string): string => {
  const approvedStates = [
    "analyzing",
    "pending",
    "processing",
    "processed",
    "review",
  ];
  if (approvedStates.includes(state)) {
    return "approved";
  }
  return state;
};

const DualApprovalHistoryTable = ({
  dualApprovalRequests,
}: DualApprovalHistoryTableType) => {
  const showControls = dualApprovalRequests.length > 1;
  const { l10n } = useLocalization();
  const [clickedDualApprovalIndex, setClickedDualApprovalIndex] =
    useState<number>(-1);

  if (!dualApprovalRequests.length) {
    return (
      <div className="emptyState">
        {l10n.getString("heading-no-approval-history")}
      </div>
    );
  }

  const handleOpenDrawer = (index: number) => {
    setClickedDualApprovalIndex(index);
  };

  const handleCloseDrawer = () => {
    setClickedDualApprovalIndex(-1);
  };

  const handleOnNext = () => {
    const nextIndex = clickedDualApprovalIndex + 1;

    // if it's the last one and there's no next, close the drawer
    if (nextIndex === dualApprovalRequests.length) {
      handleCloseDrawer();
    } else {
      setClickedDualApprovalIndex(nextIndex);
    }
  };

  const handleOnPrev = () => {
    const prevIndex = clickedDualApprovalIndex - 1;

    // if it's the first one and there's no prev, close the drawer
    if (prevIndex < 0) {
      handleCloseDrawer();
    } else {
      setClickedDualApprovalIndex(prevIndex);
    }
  };

  const isDrawerOpen = clickedDualApprovalIndex > -1;

  return (
    <>
      <Table selectable className={styles.table}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{l10n.getString("th-date")}</Table.HeaderCell>
            <Table.HeaderCell>{l10n.getString("th-to")}</Table.HeaderCell>
            <Table.HeaderCell>{l10n.getString("th-amount")}</Table.HeaderCell>
            <Table.HeaderCell>{l10n.getString("th-account")}</Table.HeaderCell>
            <Table.HeaderCell>{l10n.getString("th-status")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dualApprovalRequests.map(
            (dualApprovalRequest: DualApprovalRequest, index: number) => {
              const {
                uuid,
                created_at: createdAt,
                action,
              } = dualApprovalRequest;
              const isWirePayment = action.type === "Wire";

              const { state, dollarAmount } = action;

              // TODO: check wire and ach states match this
              const tagKind =
                action.state === "rejected_approval" ||
                action.state === "canceled"
                  ? "error"
                  : "success";

              return (
                <Table.Row
                  key={`table-row__${uuid}`}
                  onClick={() => handleOpenDrawer(index)}
                  className={styles.hoverableRow}
                >
                  <Table.Cell>
                    {Filters.americanDate(createdAt.toISOString())}
                  </Table.Cell>
                  <Table.Cell>{action.recipientName}</Table.Cell>
                  <Table.Cell>
                    {Filters.currency(dollarAmount, { hasDecimal: true })}
                  </Table.Cell>
                  <Table.Cell>
                    {isWirePayment
                      ? action.from_account_display
                      : action.recipientAccountInstitution}
                  </Table.Cell>
                  <Table.Cell>
                    <Tag
                      kind={tagKind}
                      label={Filters.humanize(mapDualApprovalStatus(state))}
                    />
                  </Table.Cell>
                  <Table.Cell className={styles.viewCtaContainer}>
                    <span className={styles.viewCtaText}>View</span>
                    <span className="fontSize--m narmi-icon-chevron-right" />
                  </Table.Cell>
                </Table.Row>
              );
            },
          )}
        </Table.Body>
      </Table>
      <DualApprovalDrawer
        handleOnNext={handleOnNext}
        handleOnPrev={handleOnPrev}
        isOpen={isDrawerOpen}
        showControls={showControls}
        handleClose={handleCloseDrawer}
        drawerType="history"
        dualApproval={dualApprovalRequests[clickedDualApprovalIndex]}
      />
    </>
  );
};

export default DualApprovalHistoryTable;
