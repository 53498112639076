import { useRequestWithFeedback } from "../../../composites";
import { useLibrary } from "../../../providers";

import { API } from "./api";

import type { ApiError } from "../../../../types/models/v1/Error.types";
import type { OverrideRunProps } from "../../../composites";

// TODO add ao API types to the namespace
export type InternalUserSessionResponse = {
  token: string;
  link: string;
};

export const useFetchAoiobLink = () => {
  const t = useLibrary("translations");
  const { throwToast } = useLibrary("toasts");
  const { send } = useRequestWithFeedback<
    InternalUserSessionResponse,
    ApiError
  >();

  const fetchAoiobLink = (
    overrideRunProps: OverrideRunProps<InternalUserSessionResponse>,
  ) => {
    send({
      action: API.fetchInternalUserSession(),
      onError: () => {
        throwToast({
          kind: "error",
          message: t.getString(
            "open-an-account-link-error",
            {},
            "An error occurred accessing your application.",
          ),
        });
      },
      ...overrideRunProps,
    });
  };

  return { fetchAoiobLink };
};
