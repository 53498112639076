import { Popover } from "@narmi/design_system";
import cc from "classcat";
import React from "react";
import Option from "./Option";
import type { Property } from "csstype";

const Options = ({
  children,
  className,
  wrapperDisplay = "inline-flex",
  matchTriggerWidth = false,
  offset = 0,
  float = "none",
  width = "auto",
  position = "relative",
}: React.PropsWithChildren<{
  className?: string;
  wrapperDisplay?: string;
  matchTriggerWidth?: boolean;
  offset?: number;
  float?: Property.Float;
  width?: string;
  position?: Property.Position;
}>) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen((o) => !o);
  };

  return (
    <div className="options-wrapper">
      <div
        className={cc(["options", { "options--open": open }])}
        style={{ width: width, position: position }}
      >
        <Popover
          closeOnContentClick
          wrapperDisplay={wrapperDisplay}
          matchTriggerWidth={matchTriggerWidth}
          offset={offset}
          onUserDismiss={() => {
            setOpen(false);
          }}
          content={<div className={`container ${className}`}>{children}</div>}
          type="plain"
        >
          <button
            onClick={handleOpen}
            onKeyDown={({ key }) => {
              if (key === " " || key === "Enter") {
                handleOpen();
              }
            }}
            className={cc(["button--reset", "options-trigger", { "options-trigger--open": open }])}
          >
            <span className="clickable narmi-icon-more-vertical" style={{ float: float }} />
          </button>
        </Popover>
      </div>
    </div>
  );
};

Options.Option = Option;

export default Options;
