import { useState, useCallback } from "react";

import {
  useRequestWithFeedback,
  type OverrideRunProps,
} from "../../../composites";
import { useLibrary } from "../../../providers";

import RequestAPI from "./api";

interface UseSensitiveCardInfoResult {
  data: API.SensitiveCardInfo | null;
  loading: boolean;
  error: boolean;
  errorMessage: string;
  successMessage: string;
  send: (
    overrideProps?: OverrideRunProps<API.SensitiveCardInfo>,
  ) => Promise<void>;
}

export const useSensitiveCardInfo = (
  cardId: string,
): UseSensitiveCardInfoResult => {
  const t = useLibrary("translations");
  const {
    send: baseSend,
    loading,
    status,
    errorMessage,
    successMessage,
  } = useRequestWithFeedback<API.SensitiveCardInfo>();
  const [data, setData] = useState<API.SensitiveCardInfo | null>(null);

  const send = useCallback(
    (overrideProps?: OverrideRunProps<API.SensitiveCardInfo>) => {
      return baseSend({
        action: RequestAPI.fetchSensitiveCardInfo(cardId),
        onData: (fetchedData) => {
          setData(fetchedData);
          overrideProps?.onData?.(fetchedData);
        },
        messaging: {
          toast: {
            error: t.getString(
              "sensitive-card-info-toast-error",
              {},
              "Unable to load card information. Try again later.",
            ),
          },
        },
        ...overrideProps,
      }).then(() => {}); // Ensures we return Promise<void>
    },
    [baseSend, cardId, t],
  );

  return {
    data,
    loading,
    error: status === "error",
    errorMessage,
    successMessage,
    send,
  };
};
