import { useEffect, useState } from "react";
import { useLocalization } from "@fluent/react";
import { entities } from "byzantine";
import { TextInput, RadioButtons } from "cerulean";
import {
  CollapsibleCard,
  ResponsiveFlex,
  Button,
  Row,
  Tooltip,
} from "@narmi/design_system";
import styles from "./RecipientDrawer.module.scss";
import { useDrawerContext } from "./DrawerValidationsProvider";
import { SectionProps } from "./RecipientDrawer";
import SectionHeader from "./SectionHeader";
import * as formAdapters from "../../../../src/adapters";

const ProfileSection = ({
  isOpen,
  onClose,
  onOpen,
  onSave,
  recipientId,
}: SectionProps) => {
  const { l10n } = useLocalization();
  const { form, handleSubmit, errorMessage, loading } =
    entities.recipients.useRecipientProfileForm(recipientId);
  const radioOptions = entities.recipients.useProfilePurposeOptions();
  const { triggerValidations, handleSaveProfile, validNotSavedSections } =
    useDrawerContext();
  const [showCancelButton, setShowCancelButton] = useState(false);

  useEffect(() => {
    setShowCancelButton(Boolean(recipientId));
  }, [recipientId]);

  const onSuccess = (data: API.CreateRecipientProfile.Response) => {
    setShowCancelButton(true);
    // only if submit is successful then we allow user to "close" the card
    handleSaveProfile();
    // we have to "reset" the form so the form reset's dirty value after being saved
    form.resetForm({ values: form.values });
    onSave(data.id);
  };

  const handleClickSave = () => {
    handleSubmit(onSuccess);
  };

  const handleClickOpen = async () => {
    const { isValid: isDrawerValid } = triggerValidations();
    if (!isDrawerValid) {
      return;
    }
    onOpen();
  };

  const handleClickClose = () => {
    const { isValid: isDrawerValid } = triggerValidations();
    if (!isDrawerValid) {
      return;
    }
    onClose();
  };

  const handleClickCancel = () => {
    form.resetForm();
    onClose();
  };

  return (
    <CollapsibleCard
      onClose={handleClickClose}
      onOpen={handleClickOpen}
      isOpen={isOpen}
      trigger="header"
      renderTitle={() => (
        <SectionHeader
          title="Profile"
          isOpen={isOpen}
          checkCondition={Boolean(recipientId)}
        />
      )}
    >
      <ResponsiveFlex gapSize="s">
        {errorMessage || validNotSavedSections.profile ? (
          <div className="message-content negative">
            <span className="narmi-icon-info margin--right--m" />
            {errorMessage}
            {validNotSavedSections.profile ? "Save details to continue." : null}
          </div>
        ) : null}
        <div className={styles.radioButtonContainer}>
          <RadioButtons
            kind="row"
            name="type"
            options={radioOptions}
            {...formAdapters.radio(form, "type")}
          />
        </div>
        <TextInput
          label="Recipient name"
          {...formAdapters.input(form, "name")}
        />
        <TextInput
          label="Nickname (optional)"
          endContent={
            <Tooltip
              text={l10n.getString("add-recipient-drawer-nickname-tooltip")}
            >
              <i className="narmi-icon-info" />
            </Tooltip>
          }
          {...formAdapters.input(form, "nickname")}
        />
        <Row alignItems="center">
          <Row.Item>
            {showCancelButton ? (
              <Button kind="negative" onClick={handleClickCancel}>
                {l10n.getString("button-cancel")}
              </Button>
            ) : null}
          </Row.Item>

          <Row.Item shrink>
            <Button
              kind="primary"
              onClick={handleClickSave}
              disabled={loading}
              isLoading={loading}
            >
              {l10n.getString("button-save")}
            </Button>
          </Row.Item>
        </Row>
      </ResponsiveFlex>
    </CollapsibleCard>
  );
};

export default ProfileSection;
