const getIsDev = (internalName) => /_local$/i.test(internalName);

const DEFAULT_TIMEOUT = 15000;

// handles timeout and errors for API requests
const _makeRequest = async (url, timeout = DEFAULT_TIMEOUT) => {
  const fetchWithTimeout = () => {
    const fetchPromise = fetch(url);
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error(`Request for "${url}" timed out after ${timeout}ms`));
      }, timeout);
    });
    return Promise.race([fetchPromise, timeoutPromise]);
  };

  try {
    const response = await fetchWithTimeout(url);
    if (!response.ok) {
      throw new Error(`HTTP error for "${url}": ${error}`);
    }
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * @object api
 * These functions are stand-ins for what will eventually
 * be calls to ApiHttp endpoints.
 */
const api = {};

/**
 * @param {String} internalName
 * @param {String} apiUrl
 * @returns {Object} parsed json manifest
 */
api.fetchFtlManifest = async (internalName, s3ImagesBucket, apiUrl) => {
  let url = `${apiUrl}translations/manifest`;
  if (!getIsDev(internalName)) {
    url = `https://${s3ImagesBucket}.s3.amazonaws.com/translations/locales/manifest.json`;
  }
  const response = await _makeRequest(url);
  const manifest = await response.json();
  return manifest;
};

/**
 * Fetches the default `en.ftl` file for a given product
 * @param {String} product Narmi product code
 * @param {String} apiUrl
 * @returns {String} content of the ftl file
 */
api.fetchDefaultFtl = async (product, apiUrl) => {
  const response = await _makeRequest(`${apiUrl}translations/${product}/default`);
  const ftl = await response.text();
  return ftl;
};

/**
 * Fetches a single institution-specific FTL file
 * @param {String} internalName
 * @param {String} latestFtlPath "latest" property from manifest
 * @param {String} apiUrl
 * @returns {String} content of the ftl file
 */
api.fetchInstitutionFtl = async (internalName, s3ImagesBucket, latestFtlPath, apiUrl) => {
  let url = `${apiUrl}translations/${latestFtlPath}`;

  if (!getIsDev(internalName)) {
    url = `https://${s3ImagesBucket}.s3.amazonaws.com/${latestFtlPath}`;
  }

  const response = await _makeRequest(url);
  const ftl = response.text();
  return ftl;
};

export default api;
