import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ComposeProviders, entities } from "byzantine";
import Account from "byzantine/src/Account";
import Dashboard from "../dashboard/Dashboard";
import SavvyMoneyDashboard from "../savvy_money/SavvyMoneyDashboard";
import { AccountContextProvider } from "../contexts/AccountContext";
import MainLayout from "./MainLayout";
import { LegacyNafUrl } from "../../types";
import ScrollToTopOnRouteChange from "../ScrollToTopOnRouteChange";

interface MainProps {
  accounts: Account[];
  features: API.Features;
  institution: API.Institution;
  legacyNafUrls: LegacyNafUrl[];
  deviceIsRemembered: boolean;
  replyTime: string;
}

const Main = ({
  accounts,
  features,
  institution,
  legacyNafUrls,
  deviceIsRemembered,
  replyTime,
}: MainProps) => {
  const Providers = [
    entities.institution.institutionBootstrap.Provider,
    entities.accountsBootstrap.Provider,
  ];

  return (
    <ComposeProviders components={Providers}>
      <AccountContextProvider accounts={accounts}>
        <Router basename="/">
          <ScrollToTopOnRouteChange />
          <Routes>
            <Route
              path="/"
              element={
                <MainLayout
                  legacyNafUrls={legacyNafUrls}
                  deviceIsRemembered={deviceIsRemembered}
                  institution={institution}
                />
              }
            >
              {["/", "/dashboard"].map((path) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <Dashboard
                      features={features}
                      institution={institution}
                      replyTime={replyTime}
                    />
                  }
                />
              ))}
              {features.olb_enable_credit_monitor && (
                <Route
                  path="/credit_monitor"
                  element={<SavvyMoneyDashboard features={features} />}
                />
              )}
              {/* Catch-all route to redirect to dashboard */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
          </Routes>
        </Router>
      </AccountContextProvider>
    </ComposeProviders>
  );
};

export default Main;
