import React from "react";
import PropTypes from "prop-types";

import { ChevronLeft } from "react-feather";
import { Button } from "@narmi/design_system";
import Feature from "byzantine/src/Feature";

export default function BaseBanner({
  goBack,
  bannerName,
  features,
  buttonName,
  orFeatureFlagChecks,
  buttonFunction,
  icon,
}) {
  /*
  base banner for redesigned pages
  from left to right, the components are:
  (1) optional chevron back button
  (2) title
  (3) optional icon
  (4) optional button performing a passed-in function
  */
  return (
    <div>
      <div className="base-banner">
        <div className="banner-content">
          {goBack && (
            <div className="banner-chevron">
              <ChevronLeft
                className="clickable"
                name="chevron-left"
                onClick={goBack}
                style={{ margin: "auto" }}
              />
            </div>
          )}
          <h1 className="banner-title" data-testid="banner-title">
            {bannerName}
          </h1>
          <div className="banner-icon">{icon}</div>
          <Feature features={features} or={orFeatureFlagChecks}>
            <div className="banner-button hide-at-m">
              {buttonName && (
                <Button
                  label={buttonName}
                  onClick={buttonFunction}
                  kind="plain"
                />
              )}
            </div>
            <div className="banner-button display-at-m">
              {buttonName && (
                <Button label={buttonName} onClick={buttonFunction} />
              )}
            </div>
          </Feature>
        </div>
      </div>
    </div>
  );
}
BaseBanner.propTypes = {
  bannerName: PropTypes.string.isRequired,
  goBack: PropTypes.func,
  buttonFunction: PropTypes.any,
  buttonName: PropTypes.string,
  features: PropTypes.object,
  orFeatureFlagChecks: PropTypes.array,
  icon: PropTypes.node,
};

BaseBanner.defaultProps = {
  buttonFunction: {},
  buttonName: "",
  features: {},
  orFeatureFlagChecks: [],
  icon: <></>,
};
