/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { actions as dualApprovalsActions } from "../dualApprovals/slice";
import { isWireDualApproval } from "../dualApprovals";

import type { PayloadAction } from "@reduxjs/toolkit";

export type WiresSlice = {
  byId: Record<API.WireId, API.Wire>;
  recipients: {
    byId: Record<API.WireRecipientId, API.WireRecipient>;
  };
};

const initialState: WiresSlice = {
  byId: {},
  recipients: {
    byId: {},
  },
};

const prepareWires = (
  accountId: API.AccountId,
  wireData: API.FetchWires.Response,
) => ({
  payload: {
    accountId,
    wires: wireData.wires,
  },
});

const prepareWireRecipients = (response: API.FetchWireRecipients.Response) => ({
  payload: {
    wireRecipients: response.results,
  },
});

const prepareCreateWireRecipient = (
  response: API.CreateOrUpdateWireRecipient.Response,
) => ({
  payload: {
    wireRecipient: response,
  },
});

const prepareDeleteWireRecipient = (id: API.WireRecipientId) => ({
  payload: {
    id,
  },
});

type ReceiveAction = ReturnType<typeof prepareWires>;
type ReceiveWireRecipientsAction = ReturnType<typeof prepareWireRecipients>;
type CreateWireRecipientsAction = ReturnType<typeof prepareCreateWireRecipient>;
type DeleteWireRecipientsAction = ReturnType<typeof prepareDeleteWireRecipient>;

const name = "wires";

const slice = createSlice({
  name,
  initialState,
  reducers: {
    receive: {
      prepare: prepareWires,
      reducer: (state, action: ReceiveAction) => {
        const { wires, accountId } = action.payload;

        const returnedWireIds: string[] = [];

        // add / update new wires received
        wires.forEach((wire) => {
          state.byId[wire.id] = wire;
          returnedWireIds.push(wire.id);
        });

        Object.values(state.byId)
          .filter((wire) => {
            return wire.from_account === accountId;
          })
          .filter((wire) => !returnedWireIds.includes(wire.id))
          .forEach((wire) => delete state.byId[wire.id]);
      },
    },

    createWireRecipient: {
      prepare: prepareCreateWireRecipient,
      reducer: (state, action: CreateWireRecipientsAction) => {
        const {
          payload: { wireRecipient },
        } = action;
        state.recipients.byId[wireRecipient.id] = wireRecipient;
      },
    },

    receiveWireRecipients: {
      prepare: prepareWireRecipients,
      reducer: (state, action: ReceiveWireRecipientsAction) => {
        action.payload.wireRecipients.forEach((wireRecipient) => {
          state.recipients.byId[wireRecipient.id] = wireRecipient;
        });
      },
    },

    deleteWireRecipient: {
      prepare: prepareDeleteWireRecipient,
      reducer: (state, action: DeleteWireRecipientsAction) => {
        const {
          payload: { id },
        } = action;
        delete state.recipients.byId[id];
      },
    },
    upsertOneWire: (state, action: PayloadAction<API.Wire>) => {
      const wire = action.payload;
      state.byId[wire.id] = wire;
    },
    upsertManyWires: (state, action: PayloadAction<Array<API.Wire>>) => {
      const wires = action.payload;
      wires.forEach((wire) => {
        state.byId[wire.id] = wire;
      });
    },
    upsertOneRecipient: (state, action: PayloadAction<API.WireRecipient>) => {
      const recipient = action.payload;
      state.recipients.byId[recipient.id] = recipient;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(dualApprovalsActions.receive, (state, action) => {
      if ("dualApprovals" in action.payload) {
        const { dualApprovals } = action.payload;
        dualApprovals?.forEach((da) => {
          if (isWireDualApproval(da)) {
            state.byId[da.wire.id] = da.wire;
          }
        });
      } else {
        const { dualApproval } = action.payload;
        if (isWireDualApproval(dualApproval)) {
          state.byId[dualApproval.wire.id] = dualApproval.wire;
        }
      }
    });
  },
});

export const { actions, reducer } = slice;

export default {
  [name]: slice.reducer,
};
