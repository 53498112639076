/* eslint-disable no-param-reassign */
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { actions as dualApprovalsActions } from "../../entities/dualApprovals/slice";
import { isACHDualApproval } from "../../entities/dualApprovals";

export type ACHPaymentSlice = {
  byId: Record<API.ACHPaymentId, API.ACHPayment>;
};

const initialState: ACHPaymentSlice = {
  byId: {},
};

const slice = createSlice({
  name: "achPayments",
  initialState,
  // TO DO - We don't have an endpoint that directly fetches ACH payments
  // so they will be inserted via extra reducers that repsond to the dual approval receive action.
  reducers: {
    receive: (state, action: PayloadAction<API.ACHPayment>) => {
      const achPayment = action.payload;
      state.byId[achPayment.id] = achPayment;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(dualApprovalsActions.receive, (state, action) => {
      if ("dualApprovals" in action.payload) {
        const { dualApprovals } = action.payload;
        dualApprovals?.forEach((da) => {
          if (isACHDualApproval(da)) {
            state.byId[da.ach_payment.id] = da.ach_payment;
          }
        });
      } else {
        const { dualApproval } = action.payload;
        if (isACHDualApproval(dualApproval)) {
          state.byId[dualApproval.ach_payment.id] = dualApproval.ach_payment;
        }
      }
    });
  },
});

export const { actions, reducer } = slice;

export default {
  achPayments: slice.reducer,
};
